import { useQuery } from '@tanstack/react-query';
import { getUsersForProviderBillingReport } from '../../services/api/user.service';
import userKeys from '../../query-keys/user-key-factory';

const useGetUsersForProviderBillingReport = ({
  providerId,
  displayLocale
}: {
  providerId: number | null | undefined;
  displayLocale?: string | undefined;
}) => {
  const usersForProviderBillingReport = useQuery({
    queryKey: userKeys.billingReport(providerId, displayLocale),
    queryFn: () => getUsersForProviderBillingReport(providerId, displayLocale),
    refetchOnMount: true,
    enabled: !!providerId
  });

  return {
    usersForProviderBillingReport
  };
};

export default useGetUsersForProviderBillingReport;
