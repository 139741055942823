import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';

import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import useGetUsersForProviderBillingReport from '../../hooks/reports/useGetUsersForProviderBillingReport';
import LoadingButton from '../ui/LoadingButton';

function BillingReportDownloadButton({
  providerId
}: {
  providerId: number | null | undefined;
}) {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const { usersForProviderBillingReport } = useGetUsersForProviderBillingReport(
    { providerId, displayLocale }
  );

  return (
    <>
      {!usersForProviderBillingReport.isFetching &&
        !usersForProviderBillingReport.data && (
          <button type="button" disabled className="btn btn-primary">
            <FormattedMessage
              id="download.report"
              defaultMessage="Download report"
            />
          </button>
        )}
      {/* show download link when there is data  */}
      {!usersForProviderBillingReport.isFetching &&
        usersForProviderBillingReport.data && (
          <a
            className="btn btn-outline-secondary"
            href={window.URL.createObjectURL(
              new Blob([usersForProviderBillingReport.data])
            )}
            download={`${providerId}-${dayjs().format('YYMMDD')}.csv`}
          >
            <FormattedMessage
              id="download.report"
              defaultMessage="Download report"
            />
          </a>
        )}
      {/* show loading button while getting data  */}
      {usersForProviderBillingReport.isFetching && (
        <LoadingButton
          type="button"
          isLoading
          onClick={() => {}}
          buttonMessage={
            <FormattedMessage
              id="report.generating"
              defaultMessage="Generating Report"
            />
          }
          loadingMessage={
            <FormattedMessage
              id="report.generating"
              defaultMessage="Generating Report"
            />
          }
        />
      )}
    </>
  );
}
export default BillingReportDownloadButton;
