import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIntl } from 'react-intl';
import { NavLink, createSearchParams } from 'react-router-dom';
import { IconLookup } from '@fortawesome/fontawesome-common-types';
import {
  faCalendarDays,
  faPeopleGroup,
  faPuzzlePiece,
  faUsersLine,
  faBuilding,
  faUniversity,
  faPersonChalkboard,
  faBookOpen,
  faTrophy,
  faUsersBetweenLines,
  faDragon,
  faSchool,
  faUsers
} from '@fortawesome/free-solid-svg-icons';

import { AppLanguage, AppRoute } from '../../../const';
import { UserType } from '../../../const/user-type';
import isAllowed from '../../../utils/permissions/isAllowed';
import { ProfileContext } from '../../../modules/profile/ProfileProvider';
import { appStrings } from '../../../modules/i18n';
import { LangContext } from '../../../modules/i18n/components/IntlWrapper';
import { OfflineContext } from '../../../modules/offline/OfflineProvider';
import AppMode from '../../../modules/offline/app-mode.enum';
import { isCentreAdmin } from '../../../utils/permissions';
import useProviderConfig from '../../../hooks/useProviderConfig';

interface SidebarItem {
  url: string;
  faIcon: IconLookup | any;
  text: any;
  allowedRoles?: UserType[];
  allowedProfile?: boolean;
  disabledOffline?: boolean;
}

function AdminSidebar({
  urlPrepend,
  handleClick
}: {
  urlPrepend?: string;
  handleClick?: () => void;
}) {
  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;

  const { schoolsAvailable } = useProviderConfig({
    providerId: Number(profile?.providerId)
  });

  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const offlineContext = useContext(OfflineContext);
  const { appMode } = offlineContext;
  const intl = useIntl();
  const langVal = (displayLocale as AppLanguage) || AppLanguage.English;

  const sidebarItems: SidebarItem[] = [
    {
      url: appStrings[langVal as AppLanguage][AppRoute.UserImpersonation],
      faIcon: faDragon,
      text: intl.formatMessage({
        id: 'sidebar.menu.impersonate',
        defaultMessage: 'Impersonate user'
      }),
      allowedRoles: [UserType.SUPER_ADMIN],
      allowedProfile: !!profile
    },
    {
      url: appStrings[langVal as AppLanguage][AppRoute.Frameworks],
      faIcon: faPuzzlePiece,
      text: intl.formatMessage({
        id: 'sidebar.menu.frameworks',
        defaultMessage: 'Frameworks'
      }),
      allowedRoles: [
        UserType.SUPER_ADMIN,
        UserType.SPORT_MANAGER,
        UserType.ADMIN
      ],
      allowedProfile: !!profile && !isCentreAdmin(profile)
    },
    {
      url: appStrings[langVal as AppLanguage][AppRoute.Providers],
      faIcon: faUniversity,
      text: intl.formatMessage({
        id: 'sidebar.menu.providers',
        defaultMessage: 'Providers'
      }),
      allowedRoles: [UserType.SUPER_ADMIN, UserType.SPORT_MANAGER],
      allowedProfile: true
    },
    {
      url: appStrings[langVal as AppLanguage][AppRoute.Centres],
      faIcon: faBuilding,
      text: intl.formatMessage({
        id: 'sidebar.menu.centres',
        defaultMessage: 'Centres',
        description: 'Centres'
      }),
      allowedRoles: [UserType.SUPER_ADMIN, UserType.ADMIN],
      allowedProfile: !!profile && !isCentreAdmin(profile)
    },
    {
      url: `${appStrings[langVal as AppLanguage][AppRoute.Centres]}/${
        profile?.centreId
      }`,
      faIcon: faBuilding,
      text: intl.formatMessage({
        id: 'sidebar.menu.centre',
        defaultMessage: 'Centre',
        description: 'Centre'
      }),
      allowedRoles: [UserType.SUPER_ADMIN, UserType.ADMIN],
      allowedProfile: !!profile && isCentreAdmin(profile)
    },
    {
      url: appStrings[langVal as AppLanguage][AppRoute.Schools],
      faIcon: faSchool,
      text: intl.formatMessage({
        id: 'sidebar.menu.schools',
        defaultMessage: 'Schools',
        description: 'Schools'
      }),
      allowedRoles: [UserType.SUPER_ADMIN, UserType.ADMIN],
      allowedProfile: !!profile && schoolsAvailable
    },
    {
      url: appStrings[langVal as AppLanguage][AppRoute.Instructors],
      faIcon: faUsersLine,
      text: intl.formatMessage({
        id: 'sidebar.menu.instructors',
        defaultMessage: 'Instructors'
      }),
      allowedRoles: [UserType.SUPER_ADMIN, UserType.ADMIN],
      allowedProfile: true
    },
    {
      url: appStrings[langVal as AppLanguage][AppRoute.Learners],
      faIcon: faPeopleGroup,
      text: intl.formatMessage({
        id: 'sidebar.menu.learners',
        defaultMessage: 'Learners'
      }),
      allowedRoles: [UserType.SUPER_ADMIN, UserType.ADMIN, UserType.INSTRUCTOR],
      allowedProfile: true,
      disabledOffline: true
    },
    {
      url: appStrings[langVal as AppLanguage][AppRoute.Users],
      faIcon: faUsers,
      text: intl.formatMessage({
        id: 'sidebar.menu.users',
        defaultMessage: 'Users',
        description: 'Users'
      }),
      allowedRoles: [UserType.SUPER_ADMIN, UserType.ADMIN],
      allowedProfile: !!profile && !isCentreAdmin(profile)
    },
    {
      url: appStrings[langVal as AppLanguage][AppRoute.Groups],
      faIcon: faUsersBetweenLines,
      text: intl.formatMessage({
        id: 'sidebar.menu.groups',
        defaultMessage: 'Groups'
      }),
      allowedRoles: [UserType.SUPER_ADMIN, UserType.ADMIN],
      allowedProfile: true
    },
    {
      url: appStrings[langVal as AppLanguage][AppRoute.Courses],
      faIcon: faPersonChalkboard,
      text: intl.formatMessage({
        id: 'sidebar.menu.courses',
        defaultMessage: 'Courses'
      }),
      allowedRoles: [
        UserType.SUPER_ADMIN,
        UserType.ADMIN,
        UserType.INSTRUCTOR,
        UserType.LEARNER
      ],
      allowedProfile: true,
      disabledOffline: true
    },
    {
      url: appStrings[langVal as AppLanguage][AppRoute.LearnerProgress],
      faIcon: faTrophy,
      text: intl.formatMessage({
        id: 'sidebar.menu.Progress',
        defaultMessage: 'Progress'
      }),
      allowedRoles: [UserType.LEARNER],
      allowedProfile: true
    },
    {
      url: `${
        appStrings[langVal as AppLanguage][AppRoute.Timetable]
      }?${createSearchParams({ bookable: 'false' })}`,
      faIcon: faCalendarDays,
      text: intl.formatMessage({
        id: 'sidebar.menu.timetable',
        defaultMessage: 'Timetable'
      }),
      allowedRoles: [UserType.INSTRUCTOR, UserType.LEARNER, UserType.ADMIN],
      allowedProfile: true,
      disabledOffline: true
    },
    {
      url: appStrings[langVal as AppLanguage][AppRoute.Resources],
      faIcon: faBookOpen,
      text: intl.formatMessage({
        id: 'sidebar.menu.Resources',
        defaultMessage: 'Resources'
      }),
      allowedRoles: [
        UserType.INSTRUCTOR,
        UserType.SUPER_ADMIN,
        UserType.LEARNER
      ],
      allowedProfile: true,
      disabledOffline: true
    },
    {
      url: appStrings[langVal as AppLanguage][AppRoute.DragonGame],
      faIcon: faDragon,
      text: intl.formatMessage({
        id: 'sidebar.menu.DragonGame',
        defaultMessage: 'Dragon Game'
      }),
      allowedRoles: [UserType.SUPER_ADMIN, UserType.LEARNER],
      allowedProfile: true,
      disabledOffline: true
    }
  ];
  return (
    <ul className="sidebar-nav">
      {sidebarItems
        .filter((item) => {
          return (
            item.allowedRoles &&
            isAllowed(item.allowedRoles, profile?.userTypeId as UserType) &&
            item.allowedProfile
          );
        })
        .map((item) => {
          const { url, faIcon, text, disabledOffline } = item;
          if (disabledOffline && appMode === AppMode.OFFLINE) {
            return (
              <li key={url} className="sidebar-item p-2">
                <div className="sidebar-item__link sidebar-item__link--offline d-flex py-2 px-3 ">
                  <FontAwesomeIcon
                    icon={faIcon}
                    className="sidebar-item__icon me-1 fa-fw align-self-center pb-1"
                  />
                  {text}
                </div>
              </li>
            );
          }
          return (
            <li key={url} className="sidebar-item p-2">
              <NavLink
                to={`${urlPrepend ? `${urlPrepend}/` : ''}${url}`}
                onClick={handleClick}
                className="sidebar-item__link d-flex py-2 px-3"
              >
                <FontAwesomeIcon
                  icon={faIcon}
                  className="sidebar-item__icon me-1 fa-fw align-self-center pb-1"
                />
                {text}
              </NavLink>
            </li>
          );
        })}
    </ul>
  );
}

export default AdminSidebar;

AdminSidebar.defaultProps = {
  urlPrepend: '',
  handleClick: () => {}
};
