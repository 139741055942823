/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-unused-prop-types */

import React, { useContext } from 'react';
import {
  ColumnFiltersState,
  getCoreRowModel,
  OnChangeFn,
  PaginationState,
  useReactTable
} from '@tanstack/react-table';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { UserType } from '../../const/user-type';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import PaginatedTable from '../tables/PaginatedTable';
import { components } from '../../types/openapi/ProviderService';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import localiseRoutePath from '../../utils/localiseRoutePath';
import { AppRoute } from '../../const';
import BillingReportDownloadButton from '../reports/BillingReportDownloadButton';
import ActiveUsersProviderCell from './ActiveUsersProviderCell';

function ActiveProviderUsersTable({
  data,
  error,
  isLoading,
  refetching,
  pagination,
  sorting,
  filtering,
  setPagination,
  setSorting,
  setFiltering,
  queryParamsLocation
}: {
  data: components['schemas']['PagedProviderDto'] | undefined | null;
  error: any;
  isLoading: boolean;
  refetching: boolean;
  pagination: PaginationState;
  sorting: any[];
  filtering: any[];
  setPagination: any;
  setSorting: any;
  setFiltering: OnChangeFn<ColumnFiltersState> | undefined;
  queryParamsLocation: string;
}) {
  const profileContext = useContext(ProfileContext);

  const { profile } = profileContext;

  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const intl = useIntl();
  const columns = [
    {
      id: 'providerName',
      header: intl.formatMessage({
        id: 'name',
        defaultMessage: 'Name',
        description: 'Name'
      }),
      cell: ({ row }: { row: any }) => {
        const provider = row.original;
        return `${provider?.name}`;
      },
      accessorKey: 'providerName'
    },
    {
      id: 'activeUsersCount',
      header: intl.formatMessage({
        id: 'active_learners.header.provider',
        defaultMessage: `Total number of active learners (in realtime)`,
        description: `Total number of active learners (in realtime)`
      }),
      cell: ({ row }: { row: any }) => {
        const provider = row.original;

        return (
          <ActiveUsersProviderCell
            topproviderId={provider.providerId}
            lang={displayLocale}
          />
        );
      },
      filterType: undefined
    },
    {
      id: 'actions',
      header: intl.formatMessage({
        id: 'actions',
        defaultMessage: 'Actions',
        description: 'Actions'
      }),
      cell: ({ row }: { row: any }) => {
        const provider = row.original;

        return (
          <div className="btn-group-vertical">
            <BillingReportDownloadButton providerId={provider.providerId} />
            <Link
              to={`providers/${row.original.providerId}/${localiseRoutePath(
                AppRoute.ViewFrameworkProviderActiveUsers
              )}`}
              className="btn btn-outline-secondary"
            >
              <FormattedMessage
                id="provider.button.viewFrameworkProviderActiveUsers"
                defaultMessage="View Provider Frameworks"
                description="View Provider Frameworks"
              />
            </Link>
          </div>
        );
      },
      filterType: undefined
    }
  ];
  const table = useReactTable({
    data: data?.providers ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    //  Sorting
    manualSorting: true,
    onSortingChange: setSorting,
    enableSorting: true,
    sortDescFirst: true,
    // Pagination
    manualPagination: true,
    onPaginationChange: setPagination,
    pageCount:
      data && data.numberOfProviders
        ? Math.ceil(data.numberOfProviders / pagination.pageSize)
        : 1,
    //  Filtering
    manualFiltering: true,
    onColumnFiltersChange: setFiltering,
    state: {
      ...{
        pagination,
        sorting,
        filtering
      }
    },
    initialState: {
      columnVisibility: {
        providerId: profile?.userTypeId === UserType.SUPER_ADMIN
      }
    },
    autoResetExpanded: false,
    debugTable: false
  });

  const resetFilters = () => {
    setPagination({ pageIndex: 0, pageSize: 10 });
    setSorting([]);
    if (setFiltering) {
      setFiltering([]);
    }
  };
  return (
    <PaginatedTable
      table={table}
      error={error}
      filtering={filtering}
      sorting={sorting}
      resetFilters={resetFilters}
      columns={columns}
      dataCount={data?.numberOfProviders}
      refetching={refetching}
      isLoading={isLoading}
      queryParamsLocation={queryParamsLocation}
      dataId="providerId"
      noneFoundMessage={
        <FormattedMessage
          id="providers.error.none"
          defaultMessage="No providers found"
          description="No providers found"
        />
      }
      showFooter={(data?.numberOfProviders ?? 0) > 10}
    />
  );
}
export default ActiveProviderUsersTable;
