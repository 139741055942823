import React from 'react';
import { useIntl } from 'react-intl';
import { UserType } from '../../const/user-type';
import useActiveUserForProviderFrameworkCount from '../../hooks/courses/useActiveUserCountForProviderFramework';
import { GeneralError } from '../common';

function ActiveUsersProviderFrameworkCell({
  topproviderId,
  lang,
  frameworkId
}: {
  topproviderId: number | null;
  lang: string;
  frameworkId: number;
}) {
  const intl = useIntl();

  if (!topproviderId) {
    return (
      <GeneralError
        message={intl.formatMessage({
          id: 'error.no_provider_id',
          defaultMessage: 'No valid provider id provided'
        })}
      />
    );
  }

  if (!frameworkId) {
    return (
      <GeneralError
        message={intl.formatMessage({
          id: 'error.no_framework_id',
          defaultMessage: 'No valid framework id provided'
        })}
      />
    );
  }
  const { activeUserCountQuery } = useActiveUserForProviderFrameworkCount({
    frameworkId: Number(frameworkId),
    providerId: Number(topproviderId),
    userType: UserType.SPORT_MANAGER,
    displayLocale: lang
  });

  return (
    <>
      {activeUserCountQuery.isLoading && <span>Loading...</span>}
      {activeUserCountQuery.data && (
        <span>{`${activeUserCountQuery.data}`}</span>
      )}
    </>
  );
}

export default ActiveUsersProviderFrameworkCell;
