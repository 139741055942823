// Format: list.<param> or parentPage.list.<param>
// eslint-disable-next-line import/prefer-default-export
export enum FilteringQueryParams {
  // Centres
  Centres = 'sp2_table_centres',
  InstructorCentres = 'sp2_table_instructor.centres',
  AdminCentres = 'sp2_table_admin.centres',
  LearnerCentres = 'sp2_table_learners.centres',
  ProviderCentres = 'sp2_table_provider.centres',
  // Learners
  Learners = 'sp2_table_learners',
  CourseLearners = 'sp2_table_course.learners',
  CourseSessionLearners = 'sp2_table_course.sessions.learners',
  GroupLearners = 'sp2_table_group.learners',
  // Instructors
  Instructors = 'sp2_table_instructors',
  CentreInstructors = 'sp2_table_centre.instructors',
  // Courses
  Courses = 'sp2_table_courses',
  CentreCourses = 'sp2_table_centre.courses',
  LearnerCourses = 'sp2_table_learner.courses',
  // Schools
  Schools = 'sp2_table_schools',
  SchoolsAdmins = 'sp2_table_schools-admins',
  SchoolsCentres = 'sp2_table_schools-centres',
  SchoolsCourses = 'sp2_table_schools-courses',
  // Providers
  Providers = 'sp2_table_providers',
  // Frameworks
  Frameworks = 'sp2_table_frameworks',
  // Groups
  Groups = 'sp2_table_groups',
  GroupAdmins = 'sp2_table_groupadmins',
  // Users
  Users = 'sp2_table_users',
  ActiveProviderUsers = 'sp2_table_providers.active-users'
}
