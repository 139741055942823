import { useQuery } from '@tanstack/react-query';
import courseKeys from '../../query-keys/course-key-factory';
import { getActiveUsersCountForProviderFramework } from '../../services/api/course.service';
import { UserType } from '../../const/user-type';

// hook for managing courses
const useActiveUserForProviderFrameworkCount = ({
  providerId,
  frameworkId,
  userType,
  displayLocale
}: {
  providerId: number;
  frameworkId: number;
  userType?: UserType | null;
  displayLocale: string | undefined;
}) => {
  const activeUserCountQuery = useQuery({
    queryKey: courseKeys.activeUsersCount(
      providerId,
      frameworkId,
      displayLocale
    ),
    queryFn: () =>
      getActiveUsersCountForProviderFramework(
        providerId,
        frameworkId,
        displayLocale
      ),
    refetchOnMount: true,
    enabled: userType === UserType.ADMIN || userType === UserType.SPORT_MANAGER
  });

  return {
    activeUserCountQuery
  };
};

export default useActiveUserForProviderFrameworkCount;
