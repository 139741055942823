import React, { lazy } from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteObject } from 'react-router-dom';

import { AppLanguage, AppRoute } from '../const';
import { appStrings } from '../modules/i18n';
import { UserType } from '../const/user-type';
import ProtectedRoute from '../modules/auth/components/ProtectedRoute';
import ViewFrameworkProviderActiveUsers from '../components/frameworks/ViewFrameworkActiveUsers';

const ErrorPage = lazy(() => import('../pages/ErrorPage'));

const MainContainer = lazy(() => import('../components/layout/MainContainer'));

export default function activeUserRoutes(
  lang: AppLanguage | string | undefined
) {
  const langVal = lang as AppLanguage;
  const routes: RouteObject = {
    path: appStrings[langVal][AppRoute.Providers],
    element: (
      <ProtectedRoute
        allowedRoles={[UserType.SUPER_ADMIN, UserType.SPORT_MANAGER]}
      >
        <MainContainer />
      </ProtectedRoute>
    ),
    errorElement: <ErrorPage />,
    handle: {
      crumb: () => (
        <FormattedMessage
          id="providers"
          defaultMessage="Providers"
          description="Providers"
        />
      )
    },
    children: [
      {
        path: `:providerId`,
        errorElement: <ErrorPage />,
        handle: {
          crumb: () => (
            <FormattedMessage
              id="provider"
              defaultMessage="Provider"
              description="Provider"
            />
          )
        },
        children: [
          {
            path: `${
              appStrings[langVal][AppRoute.ViewFrameworkProviderActiveUsers]
            }`,
            element: <ViewFrameworkProviderActiveUsers />,
            errorElement: <ErrorPage />,
            handle: {
              crumb: () => (
                <FormattedMessage
                  id="provider.activeLearners"
                  defaultMessage="Active Learners"
                  description="Active Learners"
                />
              )
            }
          }
        ]
      }
    ]
  };

  return routes;
}
