/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactNode, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { components } from '../../../types/openapi/FrameworkService';
import InlineFormError from '../../ui/InlineFormError';

interface Props {
  onSubmit: (value: {
    defaultLang: Pick<
      components['schemas']['FrameworkVersionDto'],
      'name' | 'description'
    >;
    translation: Pick<
      components['schemas']['FrameworkVersionDto'],
      'name' | 'description'
    >;
  }) => void;
  defaultLangName: string;
  defaultLang: Pick<
    components['schemas']['FrameworkVersionDto'],
    'name' | 'description'
  >;
  translationLangName: string;
  translation: Pick<
    components['schemas']['FrameworkVersionDto'],
    'name' | 'description'
  >;

  formDisabled?: boolean;
  renderSubmitButtons: (() => JSX.Element | ReactNode) | undefined;
}

function MultilingualFrameworkForm(props: Props) {
  const {
    formDisabled,
    onSubmit,
    defaultLang,
    defaultLangName,
    translationLangName,
    translation,
    renderSubmitButtons
  } = props;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid }
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      name: defaultLang.name || '',
      description: defaultLang.description || '',
      translationName: translation.name || '',
      translationDescription: translation.description || ''
    }
  });

  const handleTranslation = (values: {
    name: string;
    description: string;
    translationName: string;
    translationDescription: string;
  }) => {
    const { name, description, translationName, translationDescription } =
      values;
    const updatedValues = {
      defaultLang: { name, description },
      translation: {
        name: translationName,
        description: translationDescription
      }
    };
    onSubmit(updatedValues);
  };

  useEffect(() => {
    if (!formDisabled) {
      reset({
        name: defaultLang.name,
        description: defaultLang.description,
        translationName: translation.name,
        translationDescription: translation.description
      });
    }
  }, [
    defaultLangName,
    defaultLang,
    translationLangName,
    translation,
    formDisabled
  ]);

  return (
    <form
      className="d-flex flex-column"
      onSubmit={handleSubmit(handleTranslation)}
    >
      <div className="row">
        <div className="col-6">
          <h3>{defaultLangName}</h3>
          <div className="mb-3">
            <label htmlFor="name" className="form-label w-100">
              <FormattedMessage
                id="form.name"
                defaultMessage="Name"
                description="Name"
              />
              <input
                id="name"
                type="text"
                className="form-control"
                aria-invalid={errors.name ? 'true' : 'false'}
                {...register('name', { required: true })}
              />
            </label>
            {errors.name?.type === 'required' && (
              <InlineFormError
                className="mb-0"
                message={
                  <FormattedMessage
                    id="error.name.required"
                    defaultMessage="Name is required"
                    description="Name is required"
                  />
                }
              />
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="description" className="form-label">
              <FormattedMessage
                id="form.description"
                defaultMessage="Description"
                description="Description"
              />{' '}
            </label>
            <textarea
              id="description"
              className="form-control"
              aria-invalid={errors.description ? 'true' : 'false'}
              {...register('description', { required: true })}
            />
            {errors.description?.type === 'required' && (
              <InlineFormError
                className="mb-0"
                message={
                  <FormattedMessage
                    id="error.description.required"
                    defaultMessage="Description is required"
                    description="Description is required"
                  />
                }
              />
            )}
          </div>
        </div>
        <div className="col-6">
          <h3>{translationLangName}</h3>
          <div className="mb-3">
            <label htmlFor="translationName" className="form-label w-100">
              <FormattedMessage
                id="form.name"
                defaultMessage="Name"
                description="Name"
              />
              <input
                id="translationName"
                type="text"
                className="form-control"
                aria-invalid={errors.name ? 'true' : 'false'}
                {...register('translationName', { required: true })}
              />
            </label>
            {errors.translationName?.type === 'required' && (
              <InlineFormError
                className="mb-0"
                message={
                  <FormattedMessage
                    id="error.name.required"
                    defaultMessage="Name is required"
                    description="Name is required"
                  />
                }
              />
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="translationDescription" className="form-label">
              <FormattedMessage
                id="form.description"
                defaultMessage="Description"
                description="Description"
              />{' '}
            </label>
            <textarea
              id="translationDescription"
              className="form-control"
              aria-invalid={errors.description ? 'true' : 'false'}
              {...register('translationDescription', { required: true })}
            />
            {errors.translationDescription?.type === 'required' && (
              <InlineFormError
                className="mb-0"
                message={
                  <FormattedMessage
                    id="error.description.required"
                    defaultMessage="Description is required"
                    description="Description is required"
                  />
                }
              />
            )}
          </div>
        </div>
      </div>
      <div className="row border-top pt-3">
        {/* ovverride the default submit button eg Back/cancel */}
        {(renderSubmitButtons && renderSubmitButtons()) || (
          <button
            type="submit"
            className="btn btn-primary align-self-center"
            disabled={formDisabled || !isDirty || !isValid}
          >
            <FormattedMessage
              id="framework.translation.button"
              defaultMessage="Save translation"
              description="Save translation"
            />
          </button>
        )}
      </div>
    </form>
  );
}

export default MultilingualFrameworkForm;

MultilingualFrameworkForm.defaultProps = {
  formDisabled: false
};
