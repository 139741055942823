import { useQuery } from '@tanstack/react-query';
import { isUserASportManagerSuperUser } from '../../services/api/auth.service';
import passwordKeys from '../../query-keys/password-key-factory';

const useUserSwimwalesSuperUserCheck = ({
  userId
}: {
  userId: number | undefined;
}) => {
  const isUserSwimWalesSuperUser = useQuery({
    queryKey: passwordKeys.isUserASportManagerSuperUser(userId),
    queryFn: () =>
      userId !== undefined ? isUserASportManagerSuperUser(userId) : undefined,
    refetchOnMount: true,
    enabled: true
  });

  return {
    isUserSwimWalesSuperUser
  };
};

export default useUserSwimwalesSuperUserCheck;
