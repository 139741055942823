import { useQuery } from '@tanstack/react-query';
import courseKeys from '../../query-keys/course-key-factory';
import { getActiveUsersCountForProvider } from '../../services/api/course.service';
import { UserType } from '../../const/user-type';

// hook for managing courses
const useActiveUserForProviderCount = ({
  providerId,
  userType,
  displayLocale
}: {
  providerId: number | null | undefined;
  userType?: UserType | null;
  displayLocale: string | undefined;
}) => {
  const activeUserCountQuery = useQuery({
    queryKey: courseKeys.activeUsersForProvider(providerId!, displayLocale),
    queryFn: () => getActiveUsersCountForProvider(providerId, displayLocale),
    refetchOnMount: true,
    enabled: userType === UserType.ADMIN || userType === UserType.SPORT_MANAGER
  });

  return {
    activeUserCountQuery
  };
};

export default useActiveUserForProviderCount;
