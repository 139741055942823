/* eslint-disable @typescript-eslint/no-redeclare */
import React, { useContext, useState, useEffect, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import { LangContext } from '../../../modules/i18n/components/IntlWrapper';
import { components } from '../../../types/openapi/FrameworkService';
import Loading from '../../common/Loading';
import { GeneralError } from '../../common';
import { AppLanguage } from '../../../const';
import MultilingualVersionForm from './MultilingualVersionForm';
import useFramework from '../../../hooks/frameworks/useFramework';

function ManageFrameworkTranslation({
  frameworkId,
  onSuccess,
  renderSubmitButtons
}: {
  frameworkId: number;
  onSuccess: () => void;
  renderSubmitButtons?: () => JSX.Element | ReactNode;
}) {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const intl = useIntl();

  const [translationLocale, setTranslationLocale] = useState<
    string | undefined
  >();

  useEffect(() => {
    if (displayLocale === AppLanguage.English) {
      setTranslationLocale(AppLanguage.Welsh);
    }
    if (displayLocale === AppLanguage.Welsh) {
      setTranslationLocale(AppLanguage.English);
    }
  }, [displayLocale]);

  const { frameworkQuery, editFramework } = useFramework({
    frameworkId,
    displayLocale: displayLocale as AppLanguage
  });

  const {
    frameworkQuery: versionTranslationQuery,
    editFramework: updateTranslation
  } = useFramework({
    frameworkId,
    displayLocale: translationLocale as AppLanguage
  });

  const onSubmit = (value: {
    defaultLang: Pick<
      components['schemas']['FrameworkDto'],
      'name' | 'description'
    >;
    translation: Pick<
      components['schemas']['FrameworkDto'],
      'name' | 'description'
    >;
  }) => {
    const { defaultLang, translation } = value;
    // original exists and has changes
    if (
      displayLocale &&
      defaultLang.name &&
      defaultLang.description &&
      (defaultLang.name !== frameworkQuery.data?.name ||
        defaultLang.description !== frameworkQuery.data?.description)
    ) {
      const newFramework: components['schemas']['FrameworkDto'] = {
        name: defaultLang.name,
        description: defaultLang.description,
        frameworkId
      };

      newFramework.frameworkId = Number(frameworkId);

      editFramework.mutate(newFramework, {
        onSuccess: () => {
          onSuccess();
          toast.success(
            intl.formatMessage({
              id: 'framework.edit.success',
              defaultMessage: 'Framework has been updated'
            }),
            { delay: 200 }
          );
        }
      });
    }
    // translation exists
    if (translationLocale && translation) {
      const translatedFramework: components['schemas']['FrameworkDto'] = {
        name: translation.name,
        description: translation.description,
        frameworkId
      };

      translatedFramework.frameworkId = Number(frameworkId);

      updateTranslation.mutate(translatedFramework, {
        onSuccess: () => {
          onSuccess();
          toast.success(
            intl.formatMessage({
              id: 'framework.translation.success',
              defaultMessage: 'Framework translation has been updated'
            }),
            { delay: 200 }
          );
        }
      });
    }
  };

  if (frameworkQuery.isFetching || versionTranslationQuery.isFetching)
    return <Loading />;

  if (frameworkQuery.error || versionTranslationQuery.error)
    return <GeneralError />;

  return (
    <div>
      {frameworkQuery.data && versionTranslationQuery.data && (
        <MultilingualVersionForm
          onSubmit={onSubmit}
          formDisabled={
            frameworkQuery.isFetching ||
            versionTranslationQuery.isFetching ||
            updateTranslation.isLoading ||
            editFramework.isLoading
          }
          defaultLangName={displayLocale || ''}
          defaultLang={{
            name: frameworkQuery.data.name,
            description: frameworkQuery.data.description
          }}
          translationLangName={translationLocale || ''}
          translation={{
            name: versionTranslationQuery.data.name,
            description: versionTranslationQuery.data.description
          }}
          renderSubmitButtons={renderSubmitButtons}
        />
      )}
    </div>
  );
}

export default ManageFrameworkTranslation;

ManageFrameworkTranslation.defaultProps = {
  renderSubmitButtons: () => {}
};
