import React from 'react';
import { useIntl } from 'react-intl';
import { UserType } from '../../const/user-type';
import { GeneralError } from '../common';
import useActiveUserForProviderCount from '../../hooks/courses/useActiveUserCountForProvider';

function ActiveUsersProviderCell({
  topproviderId,
  lang
}: {
  topproviderId: number | null;
  lang: string;
}) {
  const intl = useIntl();

  if (!topproviderId) {
    return (
      <GeneralError
        message={intl.formatMessage({
          id: 'error.no_provider_id',
          defaultMessage: 'No valid provider id provided'
        })}
      />
    );
  }

  const { activeUserCountQuery } = useActiveUserForProviderCount({
    providerId: Number(topproviderId),
    userType: UserType.SPORT_MANAGER,
    displayLocale: lang
  });

  return (
    <>
      {activeUserCountQuery.isLoading && <span>Loading...</span>}
      {activeUserCountQuery.data && (
        <span>{`${activeUserCountQuery.data}`}</span>
      )}
    </>
  );
}
export default ActiveUsersProviderCell;
