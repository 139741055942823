/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-unused-prop-types */

import React, { useContext } from 'react';
import {
  ColumnFiltersState,
  getCoreRowModel,
  OnChangeFn,
  PaginationState,
  useReactTable
} from '@tanstack/react-table';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { UserType } from '../../const/user-type';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import PaginatedTable from '../tables/PaginatedTable';
import { components } from '../../types/openapi/FrameworkService';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import BillingReportDownloadButton from '../reports/BillingReportDownloadButton';
import ActiveUsersProviderFrameworkCell from './ActiveUsersProviderFrameworkCell';

function ActiveProviderFrameworkUsersTable({
  data,
  error,
  isLoading,
  refetching,
  pagination,
  sorting,
  filtering,
  setPagination,
  setSorting,
  setFiltering,
  queryParamsLocation,
  providerId
}: {
  data: components['schemas']['PagedFrameworkDto'] | undefined | null;
  error: any;
  isLoading: boolean;
  refetching: boolean;
  pagination: PaginationState;
  sorting: any[];
  filtering: any[];
  setPagination: any;
  setSorting: any;
  setFiltering: OnChangeFn<ColumnFiltersState> | undefined;
  queryParamsLocation: string;
  providerId: number;
}) {
  const profileContext = useContext(ProfileContext);

  const { profile } = profileContext;

  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const intl = useIntl();

  const columns = [
    {
      id: 'frameworkName',
      header: intl.formatMessage({
        id: 'name',
        defaultMessage: 'Name',
        description: 'Name'
      }),
      cell: ({ row }: { row: any }) => {
        const framework = row.original;
        return `${framework?.name}`;
      },
      accessorKey: 'frameworkName'
    },
    {
      id: 'activeUsersCount',
      header: intl.formatMessage({
        id: 'active_learners.header.provider.framework',
        defaultMessage: `Total number of active learners (in realtime)`,
        description: `Total number of active learners (in realtime)`
      }),
      cell: ({ row }: { row: any }) => {
        const framework = row.original;

        const providerNum = useParams();

        return (
          <ActiveUsersProviderFrameworkCell
            topproviderId={Number(providerNum.providerId)}
            lang={displayLocale}
            frameworkId={Number(framework.frameworkId)}
          />
        );
      },
      filterType: undefined
    }
  ];

  const table = useReactTable({
    data: data?.frameworks ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    //  Sorting
    manualSorting: true,
    onSortingChange: setSorting,
    enableSorting: true,
    sortDescFirst: true,
    // Pagination
    manualPagination: true,
    onPaginationChange: setPagination,
    pageCount:
      data && data.numberOfFrameworks
        ? Math.ceil(data.numberOfFrameworks / pagination.pageSize)
        : 1,
    //  Filtering
    manualFiltering: true,
    onColumnFiltersChange: setFiltering,
    state: {
      ...{
        pagination,
        sorting,
        filtering
      }
    },
    initialState: {
      columnVisibility: {
        providerId: profile?.userTypeId === UserType.SUPER_ADMIN
      }
    },
    autoResetExpanded: false,
    debugTable: false
  });

  const resetFilters = () => {
    setPagination({ pageIndex: 0, pageSize: 10 });
    setSorting([]);
    if (setFiltering) {
      setFiltering([]);
    }
  };
  return (
    <>
      <div className="btn-group-vertical">
        <BillingReportDownloadButton providerId={providerId} />
      </div>
      <PaginatedTable
        table={table}
        error={error}
        filtering={filtering}
        sorting={sorting}
        resetFilters={resetFilters}
        columns={columns}
        dataCount={data?.numberOfFrameworks}
        refetching={refetching}
        isLoading={isLoading}
        queryParamsLocation={queryParamsLocation}
        dataId="frameworkId"
        noneFoundMessage={
          <FormattedMessage
            id="frameworks.error.none"
            defaultMessage="No frameworks found"
            description="No frameworks found"
          />
        }
        showFooter={(data?.numberOfFrameworks ?? 0) > 10}
      />
    </>
  );
}
export default ActiveProviderFrameworkUsersTable;
