import React, { useContext } from 'react';
import { faLanguage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIntl, FormattedMessage } from 'react-intl';
import { ModalContext } from '../../modules/modal/ModalProvider';
import ManageFrameworkTranslation from './translation/ManageFrameworkTranslation';

function TranslateFrameworkButton({
  frameworkId,
  disabled
}: {
  frameworkId: number;
  disabled?: boolean;
}) {
  const intl = useIntl();
  const modalCtx = useContext(ModalContext);
  const { modal, closeModal } = modalCtx;

  function openTranslationModal() {
    const header = intl.formatMessage({
      id: 'manage.translation.framework',
      defaultMessage: 'Manage Translation for Framework',
      description: 'Manage Translation for Framework'
    });
    const modalContent = (
      <ManageFrameworkTranslation
        frameworkId={frameworkId}
        onSuccess={() => closeModal()}
        renderSubmitButtons={() => {
          return (
            <div className="d-flex gap-2 mx-auto w-auto flex-column flex-md-row">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => closeModal()}
              >
                <FormattedMessage
                  id="cancel"
                  defaultMessage="Cancel"
                  description="Cancel"
                />
              </button>

              <button
                type="submit"
                className="btn btn-primary"
                // onClick={() => closeModal()}
              >
                <FormattedMessage
                  id="save"
                  defaultMessage="Save"
                  description="Save"
                />
              </button>
            </div>
          );
        }}
      />
    );

    modal(modalContent, {
      // confirm: () => onModalConfirm(objective),
      header,
      hideFooter: true
    });
  }

  return (
    <button
      type="button"
      disabled={disabled}
      className="btn btn-outline-secondary"
      onClick={() => openTranslationModal()}
    >
      <span className="sr-only">
        <FormattedMessage
          id="translation"
          defaultMessage="Translation"
          description="Translation"
        />
      </span>
      <FontAwesomeIcon icon={faLanguage} />
    </button>
  );
}

export default TranslateFrameworkButton;

TranslateFrameworkButton.defaultProps = {
  disabled: false
};
