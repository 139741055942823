/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import useFramework from '../../hooks/frameworks/useFramework';
import { isAllowed } from '../../utils/permissions';
import { UserType } from '../../const/user-type';
import { ProfileContext } from '../../modules/profile/ProfileProvider';
import CardHeader from '../ui/CardHeader';
import useProviderConfig from '../../hooks/useProviderConfig';
import TranslateFrameworkButton from './TranslateFrameworkButton';
import ActiveProviderFrameworkUsersList from '../providers/ActiveProviderFrameworkUsersList';

function ViewFrameworkProviderActiveUsers() {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;
  const intl = useIntl();

  const profileContext = useContext(ProfileContext);
  const { profile } = profileContext;

  const { frameworkId, providerId } = useParams();

  const { frameworkQuery } = useFramework({
    frameworkId: Number(frameworkId),
    displayLocale
  });

  const { providerLanguages } = useProviderConfig({
    providerId: Number(profile?.providerId)
  });

  return (
    <>
      {frameworkQuery.data && (
        <div className="card border-0 rounded-top-right-lg mb-2">
          <Helmet>
            <title>
              {intl.formatMessage(
                {
                  id: 'title.provider.name_title.header',
                  defaultMessage: 'Provider: {name}'
                },
                {
                  name: frameworkQuery.data.name
                }
              )}
            </title>
          </Helmet>
          <CardHeader
            button={
              providerLanguages.length > 1 &&
              frameworkQuery.data && (
                <TranslateFrameworkButton
                  frameworkId={Number(frameworkId)}
                  disabled={false}
                />
              )
            }
          >
            <h1>
              {' '}
              <FormattedMessage
                id="framework.name_title.header"
                defaultMessage="Framework: {name}"
                description="Framework: name"
                values={{
                  name: frameworkQuery.data.name
                }}
              />
            </h1>
          </CardHeader>

          <div className="card-body">{frameworkQuery.data.description}</div>
        </div>
      )}
      {/* provider card for sport admins only */}
      {profile?.userTypeId &&
        isAllowed(
          [UserType.SPORT_MANAGER, UserType.SUPER_ADMIN],
          profile.userTypeId
        )}

      <div className="card border-0 rounded-top-right-lg mb-2">
        <div className="card-header">
          <h2>
            <FormattedMessage
              id="provider_frameworks"
              defaultMessage="Provider Frameworks"
              description="Provider Frameworks"
            />
          </h2>
        </div>
        <div className="card-body">
          <ActiveProviderFrameworkUsersList providerId={Number(providerId)} />
        </div>
      </div>
    </>
  );
}

export default ViewFrameworkProviderActiveUsers;
