import React, { useContext, useEffect, useState } from 'react';
import {
  PaginationState,
  SortingState,
  ColumnFiltersState
} from '@tanstack/react-table';
import { FilteringQueryParams } from '../../const/filtering-query-params';
import { LangContext } from '../../modules/i18n/components/IntlWrapper';
import ActiveProviderFrameworkUsersTable from './ActiveProviderFrameworkUsersTable';
import useFrameworkPaged from '../../hooks/frameworks/useFrameworksPaged';

function ActiveProviderFrameworkUsersList({
  providerId
}: {
  providerId: number;
}) {
  const langCtx = useContext(LangContext);
  const { displayLocale } = langCtx;

  const queryParamsLocation = FilteringQueryParams.ActiveProviderUsers;

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: Number(
      window.sessionStorage.getItem(`${queryParamsLocation}.pageIndex`) ?? 0
    ),
    pageSize: Number(
      window.sessionStorage.getItem(`${queryParamsLocation}.pageSize`) ?? 10
    )
  });
  const [sorting, setSorting] = useState<SortingState>(
    JSON.parse(
      window.sessionStorage.getItem(`${queryParamsLocation}.sorting`) ?? '[]'
    )
  );
  const [filtering, setFiltering] = useState<ColumnFiltersState>(
    JSON.parse(
      window.sessionStorage.getItem(`${queryParamsLocation}.filters`) ?? '[]'
    )
  );

  // side effect - store current state in sessionstorage
  useEffect(() => {
    window.sessionStorage.setItem(
      `${queryParamsLocation}.pageIndex`,
      JSON.stringify(pagination.pageIndex)
    );
    window.sessionStorage.setItem(
      `${queryParamsLocation}.pageSize`,
      JSON.stringify(pagination.pageSize)
    );
    window.sessionStorage.setItem(
      `${queryParamsLocation}.sorting`,
      JSON.stringify(sorting)
    );
    window.sessionStorage.setItem(
      `${queryParamsLocation}.filters`,
      JSON.stringify(filtering)
    );
  }, [pagination, sorting, filtering]);

  const { pagedFrameworkListQuery } = useFrameworkPaged({
    providerId,
    displayLocale,
    pageIndex: pagination.pageIndex,
    pageSize: pagination.pageSize,
    sorting,
    rawFiltering: filtering
  });
  return (
    <ActiveProviderFrameworkUsersTable
      data={pagedFrameworkListQuery.data}
      error={pagedFrameworkListQuery.error}
      isLoading={pagedFrameworkListQuery.isLoading}
      refetching={pagedFrameworkListQuery.isRefetching}
      pagination={pagination}
      sorting={sorting}
      filtering={filtering}
      setPagination={setPagination}
      setSorting={setSorting}
      setFiltering={setFiltering}
      queryParamsLocation={queryParamsLocation}
      providerId={Number(providerId)}
    />
  );
}

export default ActiveProviderFrameworkUsersList;
